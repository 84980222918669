import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoginPage } from './pages/auth/login/login.page';
import { SignupPage } from './pages/auth/signup/signup.page';
import { SecureInnerPagesGuard } from './shared/secure-inner-pages.guard';
import { AuthGuard } from './shared/auth.guard';
import { AccessGuard } from './shared/access.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'signup',
    loadChildren: () => import('./pages/auth/signup/signup.module').then(m => m.SignupPageModule),
    canActivate: [SecureInnerPagesGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/auth/login/login.module').then(m => m.LoginPageModule),
    canActivate: [SecureInnerPagesGuard]
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'list',
    loadChildren: () => import('./pages/list/list.module').then(m => m.ListPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'formar', loadChildren: './pages/formar/formar.module#FormarPageModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'parc', loadChildren: './pages/parc/parc.module#ParcPageModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'epv', loadChildren: './pages/epv/epv.module#EpvPageModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'customization', loadChildren: './pages/customization/customization.module#CustomizationPageModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'home-lemann', loadChildren: './pages/home-lemann/home-lemann.module#HomeLemannPageModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'comparacao', loadChildren: './pages/comparacao/comparacao.module#ComparacaoPageModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'rede', loadChildren: './pages/rede/rede.module#RedePageModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'captacao', loadChildren: './pages/orcamento/orcamento.module#OrcamentoPageModule',
    canActivate: [AccessGuard]
  }


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
